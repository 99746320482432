import React, { Component } from "react";
import Link from "next/link";
import Nav from 'react-bootstrap/Navbar';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Container } from "react-bootstrap";
import { withRouter } from "next/router";

// set page title
var pageTitle = 'Portfolio of Marek Kuczynski';

class Header extends Component {

  constructor(props) {
    super(props);
  }

  handleClick = (e) => {
    this.setState({ page: 0 }); 
    this.setState({ nextToken: null });
  };

  render() {

    return ( 
      <Container style = {{ maxWidth: "300px" }}>
        <Nav className = "nav" >
        <Navbar expand = "lg" variant = "light" style = {{backgroundColor: "white"}}>
          <title>
            {pageTitle}
          </title>
          <Nav>
            <Link href="/marek">about</Link>
            <NavDropdown title = "blog reader" as="div">
              <NavDropdown.Item as={Link} href="/blog/all" onClick={this.handleClick}>All Blogs </NavDropdown.Item>
              <NavDropdown.Item as={Link} href="/blog/wiz" onClick={this.handleClick}>Wiz </NavDropdown.Item>

              <div className = "dropdown-divider" />
              <NavDropdown.Item as={Link} href="/blog/compute">AWS Compute </NavDropdown.Item>
              <NavDropdown.Item as={Link} href="/blog/containers">AWS Containers </NavDropdown.Item>
              <NavDropdown.Item as={Link} href="/blog/database">AWS Database </NavDropdown.Item>
              <NavDropdown.Item as={Link} href="/blog/newsblog">AWS Newsblog </NavDropdown.Item>
              <NavDropdown.Item as={Link} href="/blog/open-source">AWS Open Source </NavDropdown.Item>
              <NavDropdown.Item as={Link} href="/blog/security">AWS Security </NavDropdown.Item>
              <NavDropdown.Item as={Link} href="/blog/whats-new">AWS Whats New </NavDropdown.Item>

              <div className = "dropdown-divider" />
              <NavDropdown.Item as={Link} href="/blog/corey">Corey Quinn </NavDropdown.Item>
              <NavDropdown.Item as={Link} href="/blog/jeremy">Jeremy Daly </NavDropdown.Item>
              <NavDropdown.Item as={Link} href="/blog/yan">Yan Cui </NavDropdown.Item>
              <NavDropdown.Item as={Link} href="/blog/werner">Werner Vogels </NavDropdown.Item>

            </NavDropdown>   
          </Nav>
        </Navbar>   
        </Nav>
      </Container>
    )
  }
}

//export default Header
export default withRouter(Header);
